@import "../../styles/responsive.scss";
.img_cpntainer {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  // margin-left: 77px;
  // margin-top: 10px;

  // @include lg {
  //   margin-left: 100px;
  //   margin-top: 10px;
  // }

  // @include md {
  //   margin-left: 150px;
  //   margin-top: 10px;
  // }

  // display: flex;
  // align-items: center;
  // justify-content: center;

  .img_Team {
    height: 200px;
    width: 200px;
    object-fit: cover;
  }
}
