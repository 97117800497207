.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

}

.carousel-content {

  display: flex;
  width: 80%;
  transition: transform 0.5s ease-in-out; /* Smooth slide transition */
}

.carousel-image {
  flex: 0 0 33.33%; /* Each image takes up one-third of the carousel width */
  margin: 0 10px;
}

.carousel-image img {
  width: 80%;
  border-radius: 10px;
}

.carousel-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 24px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.carousel-button:hover {
  background-color: #555;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
