ul,
.pagination {
  display: flex;
  align-items: center;
  gap: 10px;

  li {
    // text-decoration: underline;
    padding: 2px 6px;
    cursor: pointer;

    &:hover {
      background-color: #cbcbcb;
      color: #373737;
    }
    // border-radius: 3px;
  }
}
