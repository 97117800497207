@import "./variables.scss";
@import "./responsive.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 16px;

  .dark_mode {
    background-color: rgb(17, 16, 16);
    color: aliceblue;
    transition: background-color 0.3s, color 0.3s;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.main {
  font-family: PoppinsRegular, "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: $secondary-white-color;
  font-size: 14px;

  .container {
    display: flex;

    // @include xxl {
    //     background-color: rgba(180, 21, 169, 0.61);
    // }

    // @include xl {
    //     background-color: rgba(106, 14, 244, 0.881);
    // }

    // @include lg {
    //     background-color: rgba(174, 65, 51, 0.779);
    // }

    // @include md {
    //     background-color: rgba(154, 135, 51, 0.395);
    // }

    // @include sm {
    //     background-color: rgba(51, 154, 154, 0.395);
    // }

    .menuContainer {
      width: 250px;
      height: 100%;
      padding: 5px 20px;
      background-color: $primary-white-color;
      //margin top because of position fixed of the navbar
      margin-top: 5%;
      position: fixed;

      @include xl {
        margin-top: 63px;
      }

      @include lg {
        width: 200px;
      }

      @include md {
        width: 80px;
      }
    }

    .contentContainer {
      //margin top because of position fixed of the navbar
      margin-top: 60px;
      width: 100%;
      height: 85vh;
      padding: 20px;
      overflow-y: scroll;
      //margin left because of position fixed of the menu
      margin-left: 250px;

      @include xl {
        padding: 15px;
      }

      @include lg {
        padding: 10px 20px 10px 10px;
        margin-left: 200px;
      }

      @include md {
        padding: 20px 10px 20px 20px;
        margin-left: 70px;
      }
    }
  }
}
