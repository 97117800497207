.errorBar_ {
  width: 100%;
  background-color: #c50a0ab0;
  align-items: center;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #e7e7e7;
  z-index: 99999;
  border-radius: 5px;

  .sp1 {
    font-family: "MonserratRegular", Arial, sans-serif;
  }
}
