.immm {
  width: 180px;
  height: 100px;
  display: flex;
  justify-content: center;
  gap: 20px;
  .logo_partner {
    object-fit: contain;
    width: 180px;
    height: 100px;
  }
}
