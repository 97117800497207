.app.dark {
  background-color: #111;
  color: rgb(156, 156, 156);
  transition: background-color 0.5s, color 0.5s;

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  .home {
    background-color: #111;
    color: gray;
  }

  .table {
    background-color: #111;

    .tableCell {
      color: gray;
    }
  }

  .navbar {
    color: #999;
    border-color: #333;

    .search {
      border-color: gray;
    }
  }

  .sidebar {
    background-color: #111;
    border-color: #333;
    height: 100vh;

    .top {
      .logo {
        color: #999;
      }
    }

    hr {
      border-color: #333;
    }

    ul {
      li {
        &:hover {
          background-color: #333;
        }

        .icon {
          color: #999;
        }
      }
    }
  }

  .datatable {
    background-color: #111;
    display: inline-table;
    overflow: hidden;

    .datagrid {
      color: gray;
      border: none;

      .viewButton,
      .deleteButton,
      .cellWithStatus {
        color: gray;
        border: none;
      }
    }

    .boxQuestion {
      background-color: #111;
      color: grey;
    }
  }

  input {
    background-color: transparent;
    color: #999;
  }

  .sticky-top.navbar-dark {
    position: fixed;
    background: #0f0f0f;
    width: 100%;

    .navbar-nav .nav-link {
      color: #999;
      // width: 100%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #a1a1a1;
  }

  @media (min-width: 992px) {
    .sticky-top.navbar-dark {
      position: fixed;
      background: #0f0f0f;

      .navbar-nav .nav-link {
        color: #999;
      }
    }
  }

  .bg__dark {
    background: #0f0f0f;

    .dark__accordion {
      background-color: #e6e6e6;
    }
  }

  .dark__testimonial {
    background: #1b1a1a;
    color: #999;
  }

  .dark__service {
    // background: #1b1a1a;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 1%,
      rgba(68, 68, 68, 1) 58%,
      rgba(178, 178, 178, 1) 100%
    );
    color: #d4d4d4;

    p {
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  .dark__activity {
    background: #1b1a1a;
    color: #999;
  }
  .dark__text {
    color: #999;
  }
}
