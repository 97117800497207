.img_container {
  // max-height: 350px;
  // max-width: 500px;
  // height: 350px;
  // width: 500px;
  // background-color: black;

  .img_Activity {
    height: 200px;
    width: 500px;

    // max-height: 350px;
    // max-width: 500px;
    object-fit: cover;
  }
}
