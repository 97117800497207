@import "../../styles/responsive.scss";
.carousel_content {
  font-family: "OrbitronMedium";
  font-size: "60px";

  @include xxl {
    h1 {
      font-size: 60px;
      font-family: "OrbitronMedium";
    }
  }
  @include xl {
    h1 {
      font-size: 50px;
      font-family: "OrbitronMedium";
    }
  }
  @include md {
    h1 {
      font-size: 30px;
      font-family: "OrbitronMedium";
    }
  }
  @include sm {
    h1 {
      font-size: 22px;
      font-family: "OrbitronMedium";
    }
  }
}
