.logo {
  width: 50px;
  height: 50px;
}
.contact_Frame {
  cursor: pointer;
  &:hover {
    color: #070515;
  }
}

.grqdientt {
}

.icons_services {
  img {
    margin-bottom: 30px;
    width: 90px;
    height: 90px;
  }
}
