@import "../../styles/responsive.scss";

.logo {
  width: 50px;
  height: 50px;
}

.dropDown_container {
  display: flex;
  align-items: center;
  border-left: 1px solid #777;
  padding-left: 10px;
  gap: 10px;

  select {
    background-color: transparent;
    color: #777;
    border: none;
    outline: none;
  }

  .selectContainet {
    display: flex;
    gap: 10px;
    align-items: center;
    .ico {
      color: #9c9494ad !important;
    }
    span {
      color: #9c9494ad;
      cursor: pointer;
    }
  }
}
.darkMode_btn {
  display: flex;
  align-items: center;
  border: 1px solid #777;
  padding: 5px;
  border-radius: 20px;
  gap: 15px;
  cursor: pointer;
  .ico {
    font-size: 18px;
    color: #777777a5;
  }
}

.darkMode_btn_sm {
  display: flex;
  align-items: center;
  width: max-content;
  border: 1px solid #777;
  padding: 5px;
  border-radius: 20px;
  gap: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  .ico {
    font-size: 18px;
    color: #777777a5;
  }
  @include xxl {
    display: none;
  }
  @include md {
    display: flex;
  }
}

.bg_header {
  background: rgb(113, 113, 113);
  background: -moz-linear-gradient(
    63deg,
    rgba(113, 113, 113, 1) 0%,
    rgba(21, 21, 21, 1) 100%
  );
  background: -webkit-linear-gradient(
    63deg,
    rgba(113, 113, 113, 1) 0%,
    rgba(21, 21, 21, 1) 100%
  );
  background: linear-gradient(
    63deg,
    rgba(113, 113, 113, 1) 0%,
    rgba(21, 21, 21, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#717171",endColorstr="#151515",GradientType=1);
}

a {
  .ico {
    font-size: 18px;

    &:hover {
      color: black;
    }
  }
}
