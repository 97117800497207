.content {
  width: 90%;
  margin: 20px auto;
}

.image-left {
  float: left; /* Float the image to the left */
  margin-right: 25px; /* Add spacing between the image and text on the right */
  margin-bottom: 10px; /* Add spacing below the image for a cleaner look */
  height: 300px;
  width: 500px;
  object-fit: cover;
}

.Container_about_activity {
  line-height: 1.6; /* Improve readability of the text */
  text-align: justify;
}
